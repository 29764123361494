<template>
  <BaseButton
    class="warn-button"
    :disabled="disabled"
    @click="$emit('click')">
    {{value}}
  </BaseButton>
</template>

<script>
import BaseButton from './BaseButton'
export default {
  name: 'WarnButton',
  props: [
    'disabled',
    'value',
  ],
  components: { BaseButton },
}
</script>

<style scoped>

</style>

<template>
  <div class="card">
    <div class="title text-20-800">
      {{ $t('models.modelInfo') }}
    </div>

    <TheModel
      :model="newModel.model"
      :dimensions="dimensions"
      :hs-code="hsCode"
      :selectedType="selectedType"
      :selectedBrand="selectedBrand"
      :withHelpersData="true"
      @setModelValue="setValue"
      @onTypeSelect="onTypeSelect"
      @onBrandSelect="onBrandSelect"
      @onSelectCategory="onSelectCategory"
      @setHsCode="setHsCode"
      @setDimension="setDimension"
      @onButtonClick="createModel"
    />

    <ModalOnOverlay v-if="showLeaveModal">
      <template #header>
        {{ $t('models.confirmTitle') }}
      </template>
      <template #body>
        {{ $t('models.confirmText') }}
      </template>
      <template #buttons>
        <WarnButton
          :value="$t('models.leave')"
          @click="leave"
        />
        <simple-button
          :value="$t('models.stay')"
          @click="closeModal"
        />
      </template>
    </ModalOnOverlay>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TooltipIcon from '@/components/ui/forms/TooltipIcon'
import ModalOnOverlay from '@/components/ui/modals/ModalOnOverlay'
import SimpleButton from 'smarket-ui/src/components/buttons/SimpleButton'
import WarnButton from '@/components/ui/buttons/WarnButton'
import TheModel from '../../ui/layout/models/TheModel'

export default {
  name: 'AddNewModel',
  components: {
    TheModel,
    WarnButton,
    ModalOnOverlay,
    SimpleButton,
    TooltipIcon,
  },
  data () {
    return {
      steps: [
        {
          id: 1,
          name: this.$t('models.info'),
          isCurrent: true,
          isDone: false,
          route: '',
          hasLine: true,
        },
        {
          id: 2,
          name: this.$t('models.variation'),
          isCurrent: false,
          isDone: false,
          route: '',
          hasLine: false,
        },
      ],
      selectedType: null,
      selectedBrand: null,
      leaveRoute: '',
      showLeaveModal: false,
    }
  },
  methods: {
    onTypeSelect (type) {
      this.selectedType = type
      this.$store.dispatch('setModelType', type)
    },
    onBrandSelect (brand) {
      this.selectedBrand = brand
      this.$store.dispatch('setModelBrand', brand)
    },
    onSelectCategory (category) {
      this.$store.dispatch('setModelCategory', category)
    },
    setValue (payload) {
      this.$store.commit('removeAllErrors')
      this.$store.commit('setNewModelValues', payload)
    },
    setDimension (payload) {
      this.$store.dispatch('setDimension', [payload])
    },
    setHsCode (hsCode) {
      this.$store.dispatch('setHsCode', hsCode)
    },
    async createModel () {
      try {
        const modelCreated = await this.$store.dispatch('addModel')
        this.$store.commit('showHintNotification', {
          type: 'SUCCESS',
          title: this.$t('models.savedSuccessfully'),
          description: null,
        })
        await this.$router.push(`/create/product/${modelCreated.id}`)
      } catch (error) {

      }
    },
    closeModal () {
      this.$store.commit('hideOverlay')
      this.showLeaveModal = false
    },
    leave () {
      this.$router.push(this.leaveRoute)
    },
  },
  computed: {
    ...mapGetters([
      'isModelEdit',
      'newModel',
      'modelExists',
      'dimensions',
      'modelId',
      'hsCode',
    ]),
  },

  beforeRouteLeave (to, from, next) {
    if (localStorage.getItem('api_token') === null) {
      next()
      return
    }
    if (
      this.showLeaveModal === false &&
      to.name !== 'models.creatingProduct'
    ) {
      this.$store.commit('showOverlay')
      this.leaveRoute = to.path
      this.showLeaveModal = true
    } else {
      this.$store.commit('hideOverlay')
      this.showLeaveModal = false
      next()
    }
  },
}
</script>

<style lang="scss" scoped>
.card {
  padding: 20px;
  border-radius: 20px;
  border: 1px solid #D4DADF;
  box-shadow: 0 0 24px 0 #0000000F;
}
.title {
  margin-bottom: 20px;
}
</style>
